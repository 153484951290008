import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../../components/Layout/Page";
import Container from "../../components/Layout/Container";
// import BtnFilled from "../../components/reusable/BtnFilled";
import ContactBlock from "../../components/ContactBlock";

import Seo from "../../components/reusable/Seo";

const CaseStudy1 = () => {
  return (
    <Page className="case-study falabella">
      <Seo
        title="Case Study : Clickstream and Data Platform "
        path="case-studies/clickstream-data-platform"
        description="Built a data platform to capture 50 million clickstream events per day for a leading Ecommerce company in South Asia."
      />
      <section className="hero">
        <Container>
          <div className="text">
            <div className="h1-prefix">
              <span>Case Study</span>
            </div>
            <h1>
              <b>Clickstream &amp; Data Platform </b>
            </h1>
          </div>
          <figure>
            <StaticImage
              src="../../images/casestudy/clickstream.jpg"
              alt="Data platform to capture clickstream for ecommerce"
              layout="fullWidth"
              loading="eager"
              placeholder="blurred"
            />
          </figure>
        </Container>
      </section>
      <section className="overview">
        <Container>
          <p className="intro">
            <b>
              Built a data platform to capture 50 million clickstream events per
              day for a leading Ecommerce company in South Asia.
            </b>
          </p>
          <p>
            Client is a Fortune 500 conglomerate based out of India. Client
            wanted to build a data platform to capture clickstreams from their
            flagship ecommerce initiative.
          </p>
          <br />
          <div className="keypoints">
            <ul>
              <li>
                <span>Largest online luxury store in India</span>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="biz-value">
        <Container>
          <h2>
            <b>Business Value Delivered</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <strong>50M</strong>{" "}
                <span>Clickstream events captured per day</span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>100%</strong> <span>Success Rate </span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>12 weeks</strong>{" "}
                <span>From set-up to production </span>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="challenges">
        <Container>
          <h2>
            <b>Business Requirement</b>
          </h2>
          <ul>
            <li>
              <span>
                Collect granular, session-level data from multiple platforms
              </span>
            </li>
            <li>
              <span>
                Use the collected data to calculate various matrices in
                real-time
              </span>
            </li>
            <li>
              <span>
                Build auction logic to decide on items to be displayed
              </span>
            </li>
          </ul>
        </Container>
      </section>
      <section className="solution">
        <Container>
          <h2>
            <b>Solutions</b>
          </h2>
          <ul>
            <li>
              <span>Implemented Snowplow with client’s AWS infrastructure</span>
            </li>
            <li>
              <span>
                Automated setup of Clickstream infrastructure using AWS services
              </span>
            </li>
            <li>
              <span>
                Horizontally Scalable infrastructure spanning across multi AZs
                for high availability
              </span>
            </li>
          </ul>
        </Container>
      </section>
      <ContactBlock />
    </Page>
  );
};

export default CaseStudy1;
